import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function RushingBack() {
  return (
    <Layout>
      <Helmet title="Rushing Back | Michael Weslander" />

      <h1>Rushing Back</h1>
      <h2>Michael Weslander</h2>
      <h3>Key: D</h3>
      <h4>Tempo: 100</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>A</Ch>Back when we were running from / The things we always knew
          </p>
          <p>
            <Ch>D</Ch>Wasting hours making plans / For things we wouldn't do
          </p>
          <p>
            <Ch>C#m</Ch>I threw out my priorities
          </p>
          <p>
            <Ch>D</Ch>And stumbled down a path
          </p>
          <p>
            <Ch>A</Ch>That led to you
          </p>
        </Verse>

        <Verse>
          <p>
            <Ch>A</Ch>When I started losing grip / Of what I used to know
          </p>
          <p>
            <Ch>D</Ch>I thought that leaving was / The only way to grow
          </p>
          <p>
            <Ch>C#m</Ch>I drove away in silence
          </p>
          <p>
            <Ch>D</Ch>You listened lonely
          </p>
          <p>
            <Ch>A</Ch>To the radio
          </p>
        </Verse>

        <PreChorus>
          <p>
            <Ch>Bm</Ch>Out there on the interstate / After time had played its
            course
          </p>
          <p>
            <Ch>D</Ch>My only wish was to be laying / Right back in your arms
          </p>
        </PreChorus>

        <Chorus>
          <p>
            <Ch>F#m</Ch>Please don't in<Ch>A</Ch>terpret
          </p>
          <p>
            <Ch>D</Ch>These sta<Ch>A</Ch>le signs
          </p>
          <p>
            <Ch>F#m</Ch>As love lost in trans<Ch>A</Ch>it
          </p>
          <p>
            <Ch>D</Ch>Or cryptic good<Ch>A</Ch>byes
          </p>
          <p>
            <Ch>F#m</Ch>One of these <Ch>A</Ch>days
          </p>
          <p>
            <Ch>D</Ch>When we learn to re<Ch>A</Ch>lax
          </p>
          <p>
            <Ch>D</Ch>Our love will come rush<Ch>A</Ch>ing back<Ch>D</Ch>
          </p>
        </Chorus>

        <Verse>
          <p>Now that we are running from</p>
          <p>The things we do not know</p>
          <p>And memories accumulate</p>
          <p>Like drifts of fallen snow</p>
        </Verse>

        <Verse>
          <p>If I clear a path for you</p>
          <p>Would you come and stay a while with me</p>
          <p>I believe together we can live the dreams</p>
          <p>We never used to see</p>
        </Verse>

        <PreChorus>
          <p>
            <Ch>Bm</Ch>Out there on the interstate / After time had played its
            course
          </p>
          <p>
            <Ch>D</Ch>My only wish was to be laying / Right back in your arms
          </p>
        </PreChorus>

        <Chorus>
          <p>
            So <Ch>F#m</Ch>Please don't in<Ch>A</Ch>terpret
          </p>
          <p>
            <Ch>D</Ch>These sta<Ch>A</Ch>le signs
          </p>
          <p>
            <Ch>F#m</Ch>As love lost in trans<Ch>A</Ch>it
          </p>
          <p>
            <Ch>D</Ch>Or cryptic good<Ch>A</Ch>byes
          </p>
          <p>
            <Ch>F#m</Ch>One of these <Ch>A</Ch>days
          </p>
          <p>
            <Ch>D</Ch>When we learn to re<Ch>A</Ch>lax
          </p>
          <p>
            <Ch>D</Ch>Our love will come rush<Ch>A</Ch>ing back<Ch>D</Ch>
          </p>
        </Chorus>

        <Bridge>
          <p>F#m A</p>
          <p>F#m A</p>
          <p>F#m A</p>
          <p>Bm D</p>
        </Bridge>

        <Chorus>
          <p>
            So <Ch>F#m</Ch>Please don't in<Ch>A</Ch>terpret
          </p>
          <p>
            <Ch>D</Ch>These sta<Ch>A</Ch>le signs
          </p>
          <p>
            <Ch>F#m</Ch>As love lost in trans<Ch>A</Ch>it
          </p>
          <p>
            <Ch>D</Ch>Or cryptic good<Ch>A</Ch>byes
          </p>
          <p>
            <Ch>F#m</Ch>One of these <Ch>A</Ch>days
          </p>
          <p>
            <Ch>D</Ch>When we learn to re<Ch>A</Ch>lax
          </p>
          <p>
            <Ch>D</Ch>Our love will come rush<Ch>A</Ch>ing back<Ch>D</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
